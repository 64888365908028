.tide-data {
  font-size: x-large;
}
.custom-tooltip {
  font-size:  calc(4px + 2vmin);
  background-color: #1c212b77;
  padding-left: 10px;
  padding-right: 10px;
}

.recharts-cartesian-axis-tick-value {
  font-size: 16px;
}

.app {
  text-align: center;
  background-color: #1c212b; 
  color: white;
  min-height: 95vh;
  /*font-size: calc(10px + 1vmin);*/
}

.DayPickerInput {
  text-align: center;
  background-color: #1c212b;
  color: rgb(208, 208, 208);
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
  color: rgb(245, 183, 25);
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(98, 195, 248);
}

#today-btn {
  font-size: medium;
  color:rgb(98, 195, 248);
  cursor: pointer;
}